const firebaseConfig = {
  apiKey: 'AIzaSyC6E6FRlkajRh-x9rFUsAL1nOZEyabwhgQ',
  authDomain: 'quiz-akp-tools.firebaseapp.com',
  databaseURL: 'https://quiz-akp-tools.firebaseio.com',
  projectId: 'quiz-akp-tools',
  storageBucket: 'quiz-akp-tools.appspot.com',
  messagingSenderId: '218706494280',
  appId: '1:218706494280:web:cd7e1379e8b005b6d2697d',
  measurementId: 'G-S4SZ70HBGE',
};

export default firebaseConfig;
